.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mobile-only {
  display: none;
}

@media only screen and (max-width: 800px) {
  .no-mobile {
    display: none!important;
  }

  .mobile-only {
    display: block;
    margin: 50px;
    width: 80%;
  }

}
@media only screen and (max-width: 1200px) {
  .column-mobile {
    flex-direction: column!important;
  }
  .full-width-mobile {
    width: 100%!important;
  }

  .sunburst-container,
  .rose-container {
    margin-top: -50px!important;
  }

  .mobile-shrink {
    width: 50px;
    height: 50px;
  }

  .mobile-font-shrink {
    font-size: 18px;
  }

  
}
