.goal-label {
    display: none;
    opacity: 0%;
    width: 120px;
    transition: opacity 300ms ease;
    padding-bottom: 10px;
}

.goal-label-active {
   display: block;
   opacity: 100%;
   width: 120px;
   transition: opacity 300ms ease;
   padding-bottom: 10px;
   color: #00B8C7;
}

.goal-circle-set {
    cursor: pointer;
}

.goal-circle-set:hover .goal-label {
    display: block;
    opacity: 100%;
 }

 .goal-circle-set:hover .goal-icon{
    opacity: 0%;
 }

 .goal-icon-hidden {
   opacity: 0%;
 }

 .objective-fan-text {
    font-weight: bold;
    font-size: 0px;
    transition: font-size 300ms ease;
 }

 .goal-circle-set:hover .objective-fan-text {
    font-size: 1.75px;
 }

 .objective-fan-text-active {
   font-weight: bold;
   font-size: 1.75px;
   transition: font-size 300ms ease;
 }