.year-progress-square {
    transition: width 600ms ease, margin-right 600ms ease, margin-left 600ms ease;
    margin-right: 2px!important;
    margin-left: 2px!important;
}

.empty-year-square {
    margin-right: 2px!important;
    margin-left: 2px!important;
}

.year-progress-square:hover {
    width: 60px!important;
    /* margin-right: 0px!important;
    margin-left: 0px!important; */
    background: none!important;
    border: none!important;
}

.empty-year-square:hover {
    color: black;
}

.quarters-progress {
    transition: width 600ms ease;
}

.year-progress-square:hover .quarters-progress {
    width: 10px!important;
    visibility: visible!important;
 }

 .metric-column-content {
    width: 15vw;
}

.outcome-column-content {
    width: 15vw;
}

.org-column-content {
    width: 10vw;
}