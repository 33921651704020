.story-highlight-container {
    background-color: #E5F8FA;
    width: 100%;
    display: flex; 
    justify-content: space-evenly;
    border-radius: 25px;
    text-align: left;
    margin-top: 15px;
    padding: 10px;
}

.story-highlight-image {
    border-radius: 50%;
    border: 5px solid #0166A6;
    width: 22vw;
    height: 22vw;
    object-fit: cover;
    object-position: 50% 70%;
}

.story-highlight-image-container {
    color: #0166A6;
    width: 30vw;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.story-highlight-image-caption {
    font-size: 18px;
    font-weight: bold;
    width: 25vw;
}

.story-highlight-icon-container {
    width: 120px;
    padding: 10px;
}

.story-highlight-content-container {
    width: 48vw;
    padding-left: 0px;
    padding: 10px;
}

.story-highlight-content {
    font-size: 18px;
}
.story-highlight-headline {
    font-size: 24px;
    font-weight: bold;
    color: #0166A6;
}